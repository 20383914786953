import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {GetAPIURL} from '../../Utils/Function';
import {Helmet} from "react-helmet";
import { Controller, useForm } from "react-hook-form";

import { SpinWheel, ISpinWheelProps } from 'spin-wheel-game';
//import { SpinWheel, ISpinWheelProps } from '../../Components/SpinWheel';
import ShowEvent from '../ShowEvent';
import '../../App.css';

const PageSpiner =()=> {
    const { reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [_SpinWheel,setSpinWheel] = useState(undefined);
    const [_SpinList,setSpinList] = useState([]);
    const [_SpinerMember,setSpinerMember] = useState([]);
    const _ref = useRef();
    useEffect(() => {
        (async () => {

            _Loading();
        })();

        return ()=>{ }
    }, []);
    const _GetConfig = (segments) =>{
        const spinWheelProps = {
            segments,
            onFinished: handleSpinFinish,
            primaryColor: '#fffff',
            contrastColor: 'white',
            buttonText: 'PGF',
            isOnlyOnce: false,
            size: 400,
            upDuration: 100,
            downDuration: 600,
            fontFamily: 'Kanit',
            arrowLocation: 'center',
            showTextOnSpin: true,
            isSpinSound: false,
          };
          return spinWheelProps;
    }
    const handleSpinFinish = (result,index) => {
        console.log(`Spun to: ${result} ${index}`);

        const _spinerJSON = sessionStorage.getItem('spinerjson');
        const _spinerObj = JSON.parse(_spinerJSON);
        const _Obj = _spinerObj[index];

        if(_Obj === undefined){ return;}
        const _MemberJSON = sessionStorage.getItem('spinermember');
        let _MemberObj = [];
        
        if(_MemberJSON != undefined){
            _MemberObj= [... JSON.parse(_MemberJSON)];
        }
        _MemberObj.push(_Obj.MemberID);
        sessionStorage.setItem('spinermember',JSON.stringify(_MemberObj));
        _UpdateWinerCoupon(_Obj.CouponID);

        Swal.fire({
            title: result,
            showCancelButton : true,
            confirmButtonText: 'รับรางวัล',
            confirmButtonColor:'#d33',
            cancelButtonText:'เริ่มใหม่'
        }).then((r)=>{
            if(!r.isConfirmed){
                window.location.reload();
                return;
            }
            _ref.current?.show();
        });

    };
    const _Loading = async(MemberList)=>{
        setSpinList([]);
        setSpinWheel(undefined);
        const _MemberJSON = sessionStorage.getItem('spinermember');
        let _MemberObj = [];
            
        if(_MemberJSON != undefined && _MemberJSON != null){
            _MemberObj= [... JSON.parse(_MemberJSON)];
        }

        API({method : "post",url:"/Staff/Spiner/Coupon/1",data:{Member:_MemberObj}}).then(res=>{
            if (!res.data.Status) {
                return;
            }
            const _res = res.data.Response;

            setSpinWheel(_GetConfig([... _res]));
            setSpinList([... _res]);
            sessionStorage.setItem('spinerjson',JSON.stringify(_res));
        }).catch(res => {
            setSpinWheel([]);
        });
        
    }
    const _UpdateWinerCoupon = async(CouponID) =>{
        API({method : "put",url:"/Staff/Spiner/Coupon/"+CouponID+"/Reward"}).then(res=>{
            if (!res.data.Status) {
                return;
            }
        }).catch(res => {
        })
    }
    const _Reset = ()=>{
        sessionStorage.clear();
    }
    return (
        <>
            <Helmet>
            <style type="text/css">{`
                body{
                    background-color: #180947;
                    background-image: url('${require('../../Assets/Images/PGFBG.jpg')}');
                    background-size: 100vw 100vh;
                    background-repeat: no-repeat;
                    min-height: 100vh;
                }
            `}</style>
            </Helmet>
            <React.Fragment>
            <div className='row mt-4'>
                <div className='col-2'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src={require('../../Assets/Images/PGFLogo.png')} style={{width:'250px'}}/>
                        </div>
                    </div>
                </div>
                <div className='col-8 text-center'>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            {(_SpinWheel != undefined ? <SpinWheel {..._SpinWheel} /> : '')}
                        </div>
                    </div>                
                </div>
                <div className='col-2'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src={require('../../Assets/Images/EpassPort.png')} style={{width:'250px'}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12 text-center'>
                    <div className='btn-group'>
                        <button type='button' className='btn btn-primary btn-lg' onClick={()=>{_Reset();}}>ล้าง</button>
                        <button type='button' className='btn btn-danger btn-lg' onClick={()=>{window.location.reload();}}>Reload</button>
                        <button type='button' className='btn btn-warning btn-lg text-white' onClick={()=>{_ref.current?.show();}}>Show</button>

                    </div>
                </div>
            </div>
            </React.Fragment>
            <ShowEvent ref={_ref}/>
        </>
    )
}

export default React.memo(PageSpiner)
