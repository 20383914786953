import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {IsLogged, KeyDownNumber,GetAPIURL} from '../../Utils/Function';
import '../../App.css';
import '../../View.css';
import {KeyDownMobile} from '../../Utils/Function';
const PageVoteView =()=> {
    const [_Vote,setVote] = useState([]);
    useEffect(() => {
        (async () => {
            document.body.style.backgroundColor = "#04F404";
            _Loading();
        })();

        return ()=>{ 
            document.body.style.backgroundColor = "#ffffff";

        }
    }, []);

    const _Loading = async()=>{
        setVote([]);
        API({method : "get",url:"/Staff/Vote/Status"}).then(res=>{
            if (!res.data.Status) {
                return;
            }
            console.log(res.data.Response);
            setVote(res.data.Response);
        }).catch(res => {
        });
        setTimeout(() => {
            _Loading();
        }, 30000);
    }

    return (
        <>
            <React.Fragment>
                <div className='row mb-3'>
                    <div className='col-12 text-center'>
                        <b className='fs-bold fs-2 text-white'>ทายผลโหลต</b>
                    </div>
                </div>

                {_Vote.map((v,i)=>(
                    <>
                    {v.Teams.map((tv,ti)=>(
                        <div className='row mt-4 mb-4'>
                            <div className='col-6 text-center mt-2'>
                                <b className='fs-1 text-white'>{tv.Votes}</b>
                            </div>
                            <div className='col-6 text-center'>
                                <img src={GetAPIURL()+'/Vote/Team/'+tv.VoteTeamID+'/Image'} style={{maxWidth:'150px',maxHeight:'300px'}}/>
                            </div>
                        </div>
                    ))}
                    </>
                ))}
            </React.Fragment>

        </>
    )
}

export default React.memo(PageVoteView)
