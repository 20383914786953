import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PageHome,PageNotFound,PageRegister,PageDev,PageQR,PageVote,PageVoteView,PageSpiner,PageSpiner30K,PageSpinerVote,PageLogout} from './Pages';
const Router =() =>{
    return (
        <Routes>
            <Route path="/" element={<PageHome />} />
            <Route path="/View" element={<PageVoteView />} />
            <Route path="/QR" element={<PageQR />} />
            <Route path="/Vote" element={<PageVote />} />
            <Route path="/Spiner" element={<PageSpiner />} />
            <Route path="/Spiner/1" element={<PageSpiner />} />
            <Route path="/Spiner/2" element={<PageSpiner30K />} />
            <Route path="/Spiner/vote" element={<PageSpinerVote />} />

            <Route path="/Logout" element={<PageLogout />} />

            <Route path="*" element={<PageNotFound/>} />
        </Routes>
    )
}

export default React.memo(Router)