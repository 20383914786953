import axios from 'axios';
import {GetAPIURL} from './Utils/Function';
let _APIURL = '';
let _APIConfig = '';
_APIURL = GetAPIURL();
// eslint-disable-next-line
if (sessionStorage.getItem("Authorization") != undefined && sessionStorage.getItem("Authorization").length != 0) {
    _APIConfig = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem("Authorization")
    };
}else{
    _APIConfig = {
        'Content-Type': 'application/json'
    };
}
export default axios.create({
    baseURL: _APIURL,
    headers: _APIConfig
});