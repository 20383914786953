import React,{ useState } from 'react';
import QrReader from 'react-qr-scanner'
import Swal from 'sweetalert2';
import API from '../API';
const PageQR =()=> {
    const [_QR,setQR] = useState(true);
    const _handleError = (error)=>{
        console.log(error);
    }
    const _handleScan = (data)=>{
        if(!_QR || data === null) return;
        setQR(false);
        _QRSubmit(data.text);
    }
    const _QRSubmit = async(data)=>{
        API({method : "post",url:"/Member/"+localStorage.getItem('pgfec_login')+'/QR/'+data}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                setQR(true);

                return;
            }
            Swal.fire({
                icon: 'success',
                title: res.data.Message,
                text: 'โปรดกรุณารอสักครู่',
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: false
            }).then(()=>{
                window.location.href = '/';
            });

            //setReward(res.data.Response.Reward);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
            setQR(true);
        });
    }
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                {(_QR ? <>
                    <div className='row mt-4'>
                        <div className='col-lg-12 text-center'>
                        <QrReader
                            delay={100}
                            style={{height:320,width:320}}
                            onError={_handleError}
                            onScan={_handleScan}
                            facingMode={'environment'}
                            legacyMode={true}
                            key="environment"
                            constraints={{audio: false,video: { facingMode: "environment" }}} 
                            />
                        </div>
                    </div>
                </>:'')}
                <div className='row mt-2 mb-2'>
                    <div className='col-12 text-center'>
                        <button className='btn btn-primary btn-m' onClick={()=>{window.location.href='/';}}>กลับหน้าหลัก</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageQR)
