import React,{} from 'react';

const PageNotFound =()=> {
    
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12 text-center'>
                        <h3 className='text-danger'>ไม่พบข้อมูล</h3>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(PageNotFound)
