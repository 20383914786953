import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {IsLogged, KeyDownNumber} from '../../Utils/Function';
import '../../App.css';
import {KeyDownMobile} from '../../Utils/Function';
import MemberHome from './MemberHome';
const PageHome =()=> {
    const [_PageMode,setPageMode] = useState(1);
    const [_OTP,setOTP] = useState(false);
    const [_Mobile,setMobile] = useState('');
    const [_OTPCode,setOTPCode] = useState('');
    const [_OTPInfo,setOTPInfo] = useState(null);
    useEffect(() => {
        (async () => {
            if(localStorage.getItem('pgfec_login') != undefined){
                setPageMode(3);
            }
        })();

        return ()=>{ }
    }, []);
    const _Loading = async()=>{

    }
    const _RequestOTP = async()=>{
        if(String(_Mobile).length != 10){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบหมายเลขโทรศัพท์',
            });
            return;
        }
        setOTPCode('');
        setOTPInfo(null);
        Swal.fire({
            title: 'โปรดรอสักครู่',
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        API({method : "get",url:"/OTP/Login/"+_Mobile}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setOTPInfo(res.data.Response);
            setOTP(true);
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });

    }
    const _ClearLogin = ()=>{
        localStorage.removeItem('pgfec_login');
            localStorage.removeItem('pgfec_member');
            localStorage.removeItem('pgfec_name');

    }
    const _ConfirmOTP = async()=>{
        if(String(_OTPCode).length != 6){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบรหัส OTP',
            });
            return;
        }
        setOTPCode('');

        Swal.fire({
            title: 'โปรดรอสักครู่',
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        const _reqObj = {
            OTPRefID:_OTPInfo.OTPRefID,
            RefCode: _OTPInfo.RefCode,
            OTPCode: _OTPCode
        };

        API({method : "post",url:"/OTP/Login/"+_Mobile,data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setOTP(true);
            Swal.close();
            localStorage.removeItem('pgfec_login');
            localStorage.removeItem('pgfec_member');
            localStorage.removeItem('pgfec_name');

            localStorage.setItem('pgfec_login',res.data.Response.Session);
            localStorage.setItem('pgfec_member',res.data.Response.MemberID);
            localStorage.setItem('pgfec_name',res.data.Response.Name);
            
            setPageMode(3);
            setOTPInfo(null);
            setOTPCode('');
            setOTP(false);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });

    }
    return (
        <>
            <React.Fragment>
                {(_PageMode === 1 ? <>
                    <div className='card'>
                        <div className='card-body' style={{minHeight:'300px'}}>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h3>ลงทะเบียน</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h3 className='fs-bold'>e-PassPort</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h5 className='fs-5'>ลงทะเบียนเข้าร่วมสนุกภายในงาน</h5>
                                </div>
                            </div>
                            <div className='row mt-4 mb-4'>
                                <div className='col-lg-12 text-center'>
                                    <button className='btn btn-primary btn-lg btn-m' onClick={()=>{setOTP(false);_ClearLogin();setPageMode(2);}}>ล็อคอินเข้าระบบ</button>
                                </div>
                            </div>
                            <div className='row mt-4 mb-4'>
                                <div className='col-lg-12 text-center'>
                                    <button className='btn btn-lg text-white btn-p btn-m' onClick={()=>{window.location.href='/Register';}}>ลงทะเบียนเข้าร่วมกิจกรรม</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>:'')}

                {(_PageMode === 2 ? <>
                    <div className='card'>
                        <div className='card-body' style={{minHeight:'300px'}}>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h3>เข้าสู่ระบบ</h3>
                                </div>
                            </div>
                            <div className='row mt-2 mb-2'>
                                <div className='col-lg-12 text-center'>
                                   <h5>เข้าระบบด้วยเบอร์โทรศัพท์</h5>
                                </div>
                            </div>
                            <div className='row mt-2 mb-2'>
                                <div className='col-lg-12 text-center'>
                                    <h5>ที่ได้ลงทะเบียนไว้เรียบร้อยแล้ว</h5>
                                </div>
                            </div>
                            <div className='row mt-3 mb-3'>
                                <div className='col-lg-12 text-center'>
                                    <input type='tel' className='form-control text-center fs-4' maxLength={10} autoComplete='off' value={_Mobile} placeholder='หมายเลขโทรศัพท์มือถือ' onChange={(e)=>{setMobile(e.target.value);}} onKeyDown={KeyDownMobile} readOnly={_OTP} />
                                </div>
                            </div>
                            {(_OTP === false ? <>
                                <div className='row mt-4 mb-4'>
                                    <div className='col-lg-12 text-center'>
                                        <button className='btn btn-primary btn-m' onClick={()=>{_RequestOTP();}} disabled={(_Mobile.length != 10 ? true : false)}>รับรหัสผ่าน OTP</button>
                                    </div>
                                </div>
                            </>:'')}
                            {(_OTP ? <>
                                <div className='row mt-3 mb-2'>
                                    <div className='col-lg-12 text-center'>
                                        <h5>หมายเลข OTP</h5>
                                    </div>
                                </div>
                                <div className='row mt-3 mb-3'>
                                    <div className='col-lg-12 text-center'>
                                        <input type='text' className='form-control text-center fs-4' placeholder='หมายเลข OTP' onKeyDown={KeyDownNumber} autoComplete='off' onChange={(e)=>{setOTPCode(e.target.value);}} value={_OTPCode} />
                                    </div>
                                </div>
                                <div className='row mt-4 mb-4'>
                                    <div className='col-lg-12 text-center'>
                                        <button className='btn btn-primary btn-lg btn-m' onClick={()=>{_ConfirmOTP();}}>ยืนยันรหัส OTP</button>
                                    </div>
                                </div>
                            </>:'')}
                            
                            <div className='row mt-4 mb-4'>
                                <div className='col-lg-12 text-center'>
                                    <button className='btn btn-lg text-white btn-p btn-m'>ลงทะเบียนเข้าร่วมกิจกรรม</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>:'')}

                {(_PageMode === 3 ? <>
                    <MemberHome />
                </>:'')}

            </React.Fragment>

        </>
    )
}

export default React.memo(PageHome)
