import { classNames } from '@react-pdf-viewer/core';
import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";

const ShowEvent = forwardRef((props,ref) =>{
    const [_ModalState,setModalState] = useState(false);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        show(){
            setModalState(true);
        },

      }));
    
    return (
        <>
            {(_ModalState? <> 
            
                <div style={{position:'fixed',left:0,top:0,background:'#000',width:'100vw',height:'100vh'}} classNames='text-center'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src={require('../Assets/Images/PGFShow.jpg')} style={{maxWidth:'100vw',maxHeight:'100vh',zIndex:'-9999'}} onClick={()=>{setModalState(false); window.location.reload();}} />
                        </div>
                    </div>
                </div>
            </>:'')}
        </>)
        
});

export default React.memo(ShowEvent)