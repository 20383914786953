import React,{} from 'react';

const PageLogout =()=> {
    
    localStorage.removeItem('pgfec_login');
    localStorage.removeItem('pgfec_member');
    localStorage.removeItem('pgfec_name');

    window.location.href = "/";
}

export default React.memo(PageLogout)
