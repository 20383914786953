import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../API';
import '../App.css';
import ReactCrop, { Crop } from 'react-image-crop'

const PageDev =()=> {
    const [crop, setCrop] = useState({  unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50});
    const [src,setSRC] = useState('');
    const _FileInputRef = useRef();
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    const _OpenFile = ()=>{
        _FileInputRef.current.value = null;
        _FileInputRef.current.click();
    }
    const _SelectFile=(e)=>{
        const _File = _FileInputRef.current.files[0];

        const _URL = URL.createObjectURL(_File);
        setSRC(_URL);
    }
    return (
        <>
        <link href="https://unpkg.com/react-image-crop/dist/ReactCrop.css" rel="stylesheet" />
        <script src="https://unpkg.com/react-image-crop/dist/index.umd.cjs"></script>
        <React.Fragment>
            <button className='btn btn-success' type='button' onClick={()=>{_OpenFile();}}>Open</button> 
        <ReactCrop crop={crop} onChange={c => {
            setCrop(c);
            console.log(c);
        }}>
            <img src={src} style={{maxWidth:'200px',maxHeight:'200px'}} />
        </ReactCrop>
        <input type='file' onChange={(e)=>{_SelectFile(e)}} ref={_FileInputRef} style={{display: 'none'}} accept=".png,.jpg,.jpeg"/>

        </React.Fragment>
        </>
    )
}

export default React.memo(PageDev)
