import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';
import { GetAPIURL } from '../../Utils/Function';

const MemberHome = forwardRef((props,ref) =>{
    const [_Reward,setReward] = useState(0);
    const [_ItemList,setItemList] = useState([]);
    const [_ModalState,setModalState] = useState(false);
    useEffect(() => {
        (async () => {
            _LoadDetail();
            _LoadItem();
        })();

        return ()=>{ 
        }
    }, []);
    const _LoadDetail = async()=>{
        API({method : "get",url:"/Member/"+localStorage.getItem('pgfec_login')}).then(res=>{
            if (!res.data.Status) {
                setReward(0);
                return;
            }
            setReward(res.data.Response.Reward);
        }).catch(res => {
            setReward(0);
        });
    }
    const _LoadItem = async()=>{
        setItemList([]);
        API({method : "get",url:"/Member/"+localStorage.getItem('pgfec_login')+'/Item'}).then(res=>{
            if (!res.data.Status) {
                setReward(0);
                return;
            }
            setItemList(res.data.Response);
        }).catch(res => {
            setReward(0);
        });
    }
    const _GetCoupon = async()=>{


        Swal.fire({
            icon: 'warning',
            title: 'แลกสิทธิ์ลุ้นรางวัล หรือไม่ ?',
            showCancelButton : true,
            confirmButtonText: 'แลกสิทธิ์',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : "post",url:"/Member/"+localStorage.getItem('pgfec_login')+'/Reward'}).then(res=>{
                if (!res.data.Status) {
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message,
                    });
                    return;
                }
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message,
                    text: 'โปรดกรุณารอสักครู่',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                }).then(()=>{
                });

                setReward(res.data.Response.Reward);
            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
            });
        });
        
    }
    useImperativeHandle(ref, () => ({

      }));

      return (
        <>
            <div className='row mb-4 mt-2'>
                <div className='col-12 text-center'>
                    <button className='fs-2 btn btn-primary text-white' style={{width:'80%'}} onClick={()=>{window.location.href='/QR';}}>สแกนร่วมกิจกรรม</button>
                </div>
            </div>
            <div className='row mb-4 mt-2'>
                <div className='col-12 text-center'>
                    <button className='fs-2 btn btn-primary text-white' style={{width:'80%'}} onClick={()=>{window.location.href='/Vote';}}>ทายผลการแข่งแกม</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card bg-transparent border border-white border-4'>
                        <div className='card-body' style={{minHeight:'300px'}}>
                            <div class="row g-2">
                                {_ItemList.map((v,i)=>(
                                    <div class="col-6">
                                        <div class="p-3 itemicon text-center from-control" onClick={()=>{}}>
                                            <img src={GetAPIURL()+'/Member/Item/'+v.ItemID+'/Icon'} className={(v.Reward === false ? 'itemInActive':'')}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2 mb-3'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <b className='fs-3'>จำนวนสิทธิ์ที่คุณได้รับ {_Reward} สิทธิ์</b>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12 text-center'>
                                    <b className='fs-5 text-primary'>คุณ {localStorage.getItem('pgfec_name')}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(false? 
                <div className='row mt-2 mb-3'>
                    <div className='col-12 text-center'>
                        <button className='btn btn-m btn-danger fs-3' disabled={(_Reward>0?false:true)} onClick={()=>{_GetCoupon();}}>แลกสิทธิ์ลุ้นรางวัล</button>
                    </div>
                </div>
            :'')}
            
            <div className='row mt-2 mb-3'>
                <div className='col-12 text-center'>
                    <button className='btn btn-m btn-primary fs-3' onClick={()=>{setModalState(true);}}>ตารางกิจกรรม</button>
                </div>
            </div>
            <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <img src={require('../../Assets/Images/333920.jpg')} className='' style={{maxWidth:'100%'}}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 text-center'>
                        <button type='button' className='btn btn-danger btn-lg btn-m' onClick={()=>{setModalState(false)}}>ปิด</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>
      )
});

export default React.memo(MemberHome)